<template>
  <div>
    <!-- Preloader -->
    <div id="loader-wrapper">
      <div id="loader"></div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div>
    <header id="header-06" class="header">
      
      <navigator></navigator>

      <router-view :key="key"></router-view>
  
      <span data-nosnippet class="shape1 header-shape">
        <img src="assets/images/shape/home_6/header6_shape_1.png" alt>
      </span>
      <span data-nosnippet class="shape2 header-shape">
        <img src="assets/images/shape/home_6/header6_shape_2.png" alt>
      </span>
      <span data-nosnippet class="shape3 header-shape">
        <img src="assets/images/shape/home_6/header6_shape_3.png" alt>
      </span>
      <span data-nosnippet class="shape4 header-shape">
        <img src="assets/images/shape/home_6/header6_shape_4.png" alt>
      </span>
      <span data-nosnippet class="shape5 header-shape">
        <img src="assets/images/shape/home_6/header6_shape_5.png" alt>
      </span>
      <!-- <span data-nosnippet class="shape6 header-shape">
        <img src="assets/images/shape/home_6/header6_shape_6.png" alt>
      </span> -->

      <span data-nosnippet class="bubble1 header-shape">
        <img src="assets/images/particals_icon/fixed1.png" alt>
      </span>
      <span data-nosnippet class="bubble2 header-shape">
        <img src="assets/images/particals_icon/fixed1.png" alt>
      </span>
      <span data-nosnippet class="bubble3 header-shape">
        <img src="assets/images/particals_icon/fixed2.png" alt>
      </span>
      <span data-nosnippet class="bubble4 header-shape">
        <img src="assets/images/particals_icon/fixed4.png" alt>
      </span>

      <span class="hidden">
        <img src="https://incubie.com.br/dependencies/capa-facebook.jpg" alt="Incubie - Acompanhe a Performance de Suas Operações no Mercado Financeiro">
        <img src="https://incubie.com.br/dependencies/capa-facebook.jpg" alt="Incubie - Seu Imposto Apurado Mensalmente sem Complicação - Seu IR de Ações Simplificado">
        <img src="https://incubie.com.br/dependencies/cartao-visita-p2.jpg" alt="Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente">
        <img src="https://incubie.com.br/dependencies/perfil-facebook.jpg" alt="Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente">
        <img src="https://incubie.com.br/dependencies/logo-reverso-p1.jpg" alt="Incubie - 30 Dias Grátis - Monitore Sua Carteira">
        <img src="https://incubie.com.br/dependencies/cartao-visita-p2.jpg" alt="Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente">
        <img src="https://incubie.com.br/dependencies/logo-horizontal.png" alt="Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente">
        <img src="https://incubie.com.br/dependencies/logo-vertical.png" alt="Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente">
      
        <img src="https://incubie.com.br/dependencies/compensacao-de-perdas.jpg" alt="Incubie - Acompanhe a Compensação de Perdas do Mercado Financeiro e Recupere Cada Centavos">
        <img src="https://incubie.com.br/dependencies/meus-ativos.jpg" alt="Incubie - Carteira de Investimento Centralizada - Seus Ativos Em Um Só Lugar">
        <img src="https://incubie.com.br/dependencies/dashboard-2.jpg" alt="Incubie - Acompanhe a Performance de Suas Operações no Mercado Financeiro">
        <img src="https://incubie.com.br/dependencies/importe-de-notas.jpg" alt="Incubie - Importe Simplificado das Notas de Corretagem - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira">
        <img src="https://incubie.com.br/dependencies/notas-recebidas.jpg" alt="Incubie - Gerencie Suas Notas de Corretagem e Acompanhe as Notas de Corretagem com Imposto Apurado">
      </span>

      <div ref="particles1" id="particles1" class="particles"></div>
    </header>
    <!-- End Header -->

    <layout-footer></layout-footer>
    <cookie-consent></cookie-consent>

  </div>
</template>
<script>

    import 'bootstrap'
    import 'magnific-popup'
    import 'countdown'

    import moment           from 'moment'
    import LayoutFooter     from './Footer.vue'
    import Navigator        from './Navigator.vue'
    import CookieConsent    from './CookieConsent.vue'
  
  import '../../public/assets/js/third-party/particles.js'

  export default {
      name: 'Layout',
      components: {
          Navigator,
          LayoutFooter,
          CookieConsent
      },
      methods: {
          buildParticles: function () {
                particlesJS(this.$refs.particles1,  this.$i18n.t('particles[0]'));
          }
      },
      computed: {
          key() {
              return this.$route.path
          }
      },
      mounted() {

        setTimeout(function(){
            $('body').addClass('loaded');
        }, 3000);
        

        $('#faq-area-06  .accordion-single .panel-heading').on('click', function(e){
            $('#faq-area-06  .accordion-single .panel-heading').removeClass("active");
            $(this).addClass("active");
        })

        this.buildParticles()
        new WOW().init()
      }
  };
</script>
<style scoped>

    @import '~bootstrap/dist/css/bootstrap.css';

    @import '../../public/assets/css/third-party/font-awesome.min.css';
    @import '../../public/assets/css/third-party/animate.css';
    @import '../../public/assets/css/third-party/preloader.css';
    @import '../../public/assets/css/style.css';
    @import '../../public/assets/css/incubie.css';
    @import '../../public/assets/css/responsive.css';

    @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

</style>

<template>
    <div class="container">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6">
            <div class="intro-text">
              <h1> {{ $t('message.corporate.home.labels.title') }} </h1>
              <p>{{ $t('message.corporate.home.labels.subtitle') }}</p>
              <div class="btn_video_wrapper">
                <a href="https://oauth.incubie.com.br/#/credentials/register" class="btn btn-default btn-default-style"> {{ $t('message.corporate.home.buttom.registers') }} </a>
                <!-- <div class="video_wrapper">
                  <div class="video-play-btn">
                    <span>
                      <a href="https://www.youtube.com/watch?v=D-6rzZC2Q8Y" class="video-iframe">
                        <i class="fa fa-play"></i>
                        <span class="video_title">Presentation</span>
                      </a>
                    </span>
                  </div>
                </div> -->
              </div>
              <div class="countdown_time">
                <h3> {{ $t('message.corporate.home.labels.getStarted') }} </h3>
                <div class="clock-countdown">
                  <div class="site-config"></div>
                  <div class="coundown-timer d-flex justify-content-center">
                    <div class="single-counter d-flex flex-column">
                      <h4>
                        <span class="days count-time gradient-color">00</span>
                      </h4>
                      <span class="normal"> {{ $t('message.corporate.home.fields.days') }} </span>
                    </div>
                    <div class="single-counter d-flex flex-column">
                      <h4>
                        <span class="hours count-time gradient-color">00</span>
                      </h4>
                      <span class="normal"> {{ $t('message.corporate.home.fields.hours') }} </span>
                    </div>
                    <div class="single-counter d-flex flex-column">
                      <h4>
                        <span class="minutes count-time gradient-color">00</span>
                      </h4>
                      <span class="normal"> {{ $t('message.corporate.home.fields.minutes') }} </span>
                    </div>
                    <div class="single-counter d-flex flex-column">
                      <h4>
                        <span class="seconds count-time gradient-color">00</span>
                      </h4>
                      <span class="normal"> {{ $t('message.corporate.home.fields.seconds') }} </span>
                    </div>
                  </div>
                </div>
                <div class="count-time_btn">
                  <a href="https://oauth.incubie.com.br/#/credentials/register" title class="gradient-color">
                    <span>{{ $t('message.corporate.home.labels.launch') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-12 col-lg-6 img-wrapper">
            <div class="intro-img">
              <div data-nosnippet class="bounce_wrapper">
                <img src="assets/images/header-06.png" alt>
                <div class="shape-1">
                  <div class="box bounce-1">
                    <img src="assets/images/bounce/header1_b.png" alt>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>

    import '../../../public/assets/js/third-party/jquery.downCount2.js'

    export default {
        name: 'Home',
        data() {
            return {
                date: '08/10/2021 20:35:10',
                offset: '+7',
            }
        },
        mounted() {
          
            document.title = "Incubie - Resultado dos Trades Simplificado e IR Calculado Todo Mês"

            const self = this
            $('.clock-countdown').downCount({
                  date: self.date,
                  offset: self.offset
                }, function () {
            })
        
            // $('.video-iframe').magnificPopup({
            //     type: 'iframe',
            //     iframe: {
            //         markup: '<div class="mfp-iframe-scaler">' +
            //             '<div class="mfp-close"></div>' +
            //             '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
            //             '</div>',
            //         patterns: {
            //             youtube: {
            //                 index: 'youtube.com/',
            //                 id: 'v=',
            //                 src: 'http://www.youtube.com/embed/%id%?autoplay=1'
            //             }
            //         },
            //         srcAction: 'iframe_src'
            //     }
            // })
        }
    }
</script>
<style></style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "header",
        { staticClass: "header", attrs: { id: "header-06" } },
        [
          _c("navigator"),
          _vm._v(" "),
          _c("router-view", { key: _vm.key }),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _vm._m(6),
          _vm._v(" "),
          _vm._m(7),
          _vm._v(" "),
          _vm._m(8),
          _vm._v(" "),
          _vm._m(9),
          _vm._v(" "),
          _vm._m(10),
          _vm._v(" "),
          _c("div", {
            ref: "particles1",
            staticClass: "particles",
            attrs: { id: "particles1" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("layout-footer"),
      _vm._v(" "),
      _c("cookie-consent")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "loader-wrapper" } }, [
      _c("div", { attrs: { id: "loader" } }),
      _vm._v(" "),
      _c("div", { staticClass: "loader-section section-left" }),
      _vm._v(" "),
      _c("div", { staticClass: "loader-section section-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "shape1 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: {
            src: "assets/images/shape/home_6/header6_shape_1.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "shape2 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: {
            src: "assets/images/shape/home_6/header6_shape_2.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "shape3 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: {
            src: "assets/images/shape/home_6/header6_shape_3.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "shape4 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: {
            src: "assets/images/shape/home_6/header6_shape_4.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "shape5 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: {
            src: "assets/images/shape/home_6/header6_shape_5.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "bubble1 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: { src: "assets/images/particals_icon/fixed1.png", alt: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "bubble2 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: { src: "assets/images/particals_icon/fixed1.png", alt: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "bubble3 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: { src: "assets/images/particals_icon/fixed2.png", alt: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "bubble4 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: { src: "assets/images/particals_icon/fixed4.png", alt: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "hidden" }, [
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/capa-facebook.jpg",
          alt:
            "Incubie - Acompanhe a Performance de Suas Operações no Mercado Financeiro"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/capa-facebook.jpg",
          alt:
            "Incubie - Seu Imposto Apurado Mensalmente sem Complicação - Seu IR de Ações Simplificado"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/cartao-visita-p2.jpg",
          alt:
            "Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/perfil-facebook.jpg",
          alt:
            "Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/logo-reverso-p1.jpg",
          alt: "Incubie - 30 Dias Grátis - Monitore Sua Carteira"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/cartao-visita-p2.jpg",
          alt:
            "Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/logo-horizontal.png",
          alt:
            "Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/logo-vertical.png",
          alt:
            "Incubie - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira - Imposto Calculado Mensalmente"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/compensacao-de-perdas.jpg",
          alt:
            "Incubie - Acompanhe a Compensação de Perdas do Mercado Financeiro e Recupere Cada Centavos"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/meus-ativos.jpg",
          alt:
            "Incubie - Carteira de Investimento Centralizada - Seus Ativos Em Um Só Lugar"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/dashboard-2.jpg",
          alt:
            "Incubie - Acompanhe a Performance de Suas Operações no Mercado Financeiro"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/importe-de-notas.jpg",
          alt:
            "Incubie - Importe Simplificado das Notas de Corretagem - Acompanhe Seus Resultados no Mercado - Monitore Sua Carteira"
        }
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://incubie.com.br/dependencies/notas-recebidas.jpg",
          alt:
            "Incubie - Gerencie Suas Notas de Corretagem e Acompanhe as Notas de Corretagem com Imposto Apurado"
        }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5bdd938c", { render: render, staticRenderFns: staticRenderFns })
  }
}

import LanguageStore          from './language/LanguageStore'
import createLogger           from 'vuex/dist/logger'
    
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
    modules: {
        language:   LanguageStore.language
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})

export default store;
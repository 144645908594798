import VueRouter            from 'vue-router'

import { i18n }             from '../locales/i18n'

import store                from '../stores/configurations'

import Home                 from '../components/pages/Home.vue'


import about                from './institutional/about'
import contact              from './institutional/contact'
import faq                  from './institutional/faq'
import feature              from './institutional/features'
import partner              from './institutional/partners'
import roadmap              from './institutional/roadmap'
import price                from './institutional/price'
import subscribe            from './institutional/subscribe'
import welcome              from './institutional/welcome'
import privacy              from './institutional/privacy'
import termsOfUse           from './institutional/terms-of-use'

const routing = [
    { path: '/', component: Home },
];

const routes = routing.concat(about, contact, faq, feature, 
    partner, privacy, roadmap, price, subscribe, welcome, termsOfUse);

const Router = new VueRouter({
    mode: 'history',
    routes
})

Router.afterEach((to, from, next) => { })

Router.beforeEach((to, from, next) => {
    if (store.state.language.language && store.state.language.language !== i18n.locale) {
      i18n.locale = store.state.language.language;
      next();
    } else {
        i18n.locale = navigator.languages[0];
        next();
    }
})
  
  export default Router;
<template>
    
    <footer id="footer-06" class="footer">
      <div class="container">
        <div class="row footer-btm-wrapper">
          <div class="col-md-12 col-lg-12">
            <div class="footer_items">
              <div class="footer-single-col footer_single_list_1">
                <h3 class="subtitle_1">
                  &nbsp;
                  <img src="assets/images/gradient-logo.png" alt>
                </h3>
                <p v-html="$t('message.corporate.footer.labels.description')"> </p>
                <ul class="social-links list-unstyled ml-auto">
                  <li class="nav-item">
                    <a href="/">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="footer-single-col footer_single_list_2">
                <h3 class="subtitle_1"> {{ $t('message.corporate.footer.labels.company') }} </h3>
                <ul class="list-unstyled">
                  <li>
                    <router-link @click.native="scrollTop" to="/welcome"> {{ $t('message.corporate.footer.labels.welcome') }} </router-link>
                  </li>
                  <li>
                    <router-link @click.native="scrollTop" to="/about"> {{ $t('message.corporate.footer.labels.about') }} </router-link>
                  </li>
                  <li>
                    <router-link @click.native="scrollTop" to="/contact"> {{ $t('message.corporate.footer.labels.work') }} </router-link>
                  </li>
                </ul>
              </div>

              <div class="footer-single-col footer_single_list_3">
                <h3 class="subtitle_1">{{ $t('message.corporate.footer.labels.informations') }}</h3>
                <ul class="list-unstyled">
                  <li>
                    <router-link @click.native="scrollTop" to="/contact"> {{ $t('message.corporate.footer.labels.contact') }} </router-link>
                  </li>
                  <li>
                    <router-link @click.native="scrollTop" to="/roadmap"> {{ $t('message.corporate.footer.labels.roadmap') }} </router-link>
                  </li>
                  <li>
                    <router-link @click.native="scrollTop" to="/privacy"> {{ $t('message.corporate.footer.labels.privacy') }} </router-link>
                  </li>
                  <li>
                    <router-link @click.native="scrollTop" to="/terms-of-use"> {{ $t('message.corporate.footer.labels.termsConditions') }} </router-link>
                  </li>
                </ul>
              </div>

              <div class="footer-single-col footer_single_list_4">
                <h3 class="subtitle_1"> {{ $t('message.corporate.footer.labels.services') }} </h3>
                <ul class="list-unstyled">
                  <li>
                    <router-link @click.native="scrollTop" to="/features"> {{ $t('message.corporate.footer.labels.services') }} </router-link>
                  </li>
                  <li>
                    <router-link @click.native="scrollTop" to="/price"> {{ $t('message.corporate.footer.labels.price') }} </router-link>
                  </li>
                  <li>
                    <router-link @click.native="scrollTop" to="/common-questions"> {{ $t('message.corporate.footer.labels.faq') }} </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p> {{ $t('message.corporate.footer.labels.name') }} <br/> {{ $t('message.corporate.footer.labels.taxId') }} <br/> {{ $t('message.corporate.footer.labels.address') }} </p>
      </div>
      <span data-nosnippet class="shape1 header-shape">
        <img src="assets/images/shape/home_6/footer_shape_1.png" alt>
      </span>
      <span data-nosnippet class="shape2 header-shape">
        <img src="assets/images/shape/home_6/footer_shape_2.png" alt>
      </span>
      <span data-nosnippet class="shape3 header-shape">
        <img src="assets/images/shape/home_6/footer_shape_3.png" alt>
      </span>
    </footer>

</template>
<script>

    import moment   from 'moment'

    export default {
        name: 'Footer',
        methods: {
          scrollTop: function() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        },
        computed: {
          year() {
              return moment().year()
          }
        }
    }
</script>
<style>
  .footer {
    z-index: 10
  }
</style>


import Vue                      from 'vue'
import Vuex                     from 'vuex'
import VueRouter                from 'vue-router'
import Moment                   from 'vue-moment'
import App                      from './App.vue'

// internationalization configurations
import { i18n }                 from './locales/i18n'

// routes configurations
import router                   from './router/routes'

// store configurations
import store                    from './stores/configurations'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Moment)

new Vue({
  i18n,
  store,
  router,
  el: '#app-content',
	render: h => h(App)
})

<template>
    <layout></layout>
</template>

<script>
    import 'pace-progressbar';

    import Layout   from './components/Layout.vue'

    export default {
        name: "app",
        components: {
            Layout
        },
        mounted() {
            window.paceOptions = {
                ajax: false, // disabled
                document: false, // disabled
                eventLag: false, // disabled
                elements: false,
                restartOnRequestAfter: false
            }
        }
    };
</script>

<style></style>

<template>
    <nav class="navbar navbar-expand-md navbar-light bg-faded institutional_nav">
        <div class="container">
          <button
            class="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>
          <router-link class="navbar-brand" data-scroll to="/">
            <img src="assets/images/gradient-logo.png" alt="Calculadora de IR e Acompanhamento de Performance na Bolsa de Valores">
          </router-link>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <router-link class="nav-link" to="/about"> {{ $t('message.corporate.navigator.labels.about') }} </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/features"> {{ $t('message.corporate.navigator.labels.services') }} </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/prices"> {{ $t('message.corporate.navigator.labels.price') }} </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/common-questions"> {{ $t('message.corporate.navigator.labels.faq') }} </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact"> {{ $t('message.corporate.navigator.labels.contact') }} </router-link>
              </li>
            </ul>
          </div>
          <div class="language">
            <a href="https://oauth.incubie.com.br/#/credentials/register" class="token"> {{ $t('message.corporate.navigator.labels.signin') }} </a>
            <div class="dropdown">
              <span>
                {{ $t('message.corporate.navigator.lang.portuguese') }}
                <i class="fas fa-chevron-down"></i>
              </span>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="/">  {{ $t('message.corporate.navigator.lang.portuguese') }} </a>
                </li>
                <li>
                  <a class="dropdown-item" href="/">  {{ $t('message.corporate.navigator.lang.english') }} </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
</template>
<script>

    import '../../public/assets/js/third-party/jquery.sticky.js'

    export default {
        name: 'Navigator',
        mounted() {
            $(".institutional_nav").sticky({ topSpacing: 0 });
            $('body').scrollspy({ target: '.institutional_nav' })

            $("nav a").on('click', function(event) {
                if (this.hash !== "" && this.hash !== '#/credentials/register') {
                    event.preventDefault()
                    const hash = this.hash

                    $('html, body').animate({
                        scrollTop: $(hash).offset().top
                    }, 1500, function(){
                        window.location.hash = hash;
                    })
                }
            });
        }
    }
</script>
<style>
    @import '~jquery.sticky/dist/sticky.css';
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand-md navbar-light bg-faded institutional_nav"
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "navbar-brand",
              attrs: { "data-scroll": "", to: "/" }
            },
            [
              _c("img", {
                attrs: {
                  src: "assets/images/gradient-logo.png",
                  alt:
                    "Calculadora de IR e Acompanhamento de Performance na Bolsa de Valores"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "navbarSupportedContent" }
            },
            [
              _c("ul", { staticClass: "navbar-nav ml-auto" }, [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "nav-link", attrs: { to: "/about" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("message.corporate.navigator.labels.about")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "nav-link", attrs: { to: "/features" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "message.corporate.navigator.labels.services"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "nav-link", attrs: { to: "/prices" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("message.corporate.navigator.labels.price")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: "/common-questions" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("message.corporate.navigator.labels.faq")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "nav-link", attrs: { to: "/contact" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "message.corporate.navigator.labels.contact"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "language" }, [
            _c(
              "a",
              {
                staticClass: "token",
                attrs: {
                  href: "https://oauth.incubie.com.br/#/credentials/register"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("message.corporate.navigator.labels.signin")
                    ) +
                    " "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown" }, [
              _c("span", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("message.corporate.navigator.lang.portuguese")
                    ) +
                    "\n            "
                ),
                _c("i", { staticClass: "fas fa-chevron-down" })
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "dropdown-menu" }, [
                _c("li", [
                  _c(
                    "a",
                    { staticClass: "dropdown-item", attrs: { href: "/" } },
                    [
                      _vm._v(
                        "  " +
                          _vm._s(
                            _vm.$t(
                              "message.corporate.navigator.lang.portuguese"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    { staticClass: "dropdown-item", attrs: { href: "/" } },
                    [
                      _vm._v(
                        "  " +
                          _vm._s(
                            _vm.$t("message.corporate.navigator.lang.english")
                          ) +
                          " "
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler navbar-toggler-right",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("i", { staticClass: "fas fa-bars" })]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-823dce12", { render: render, staticRenderFns: staticRenderFns })
  }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-12 col-lg-6" }, [
        _c("div", { staticClass: "intro-text" }, [
          _c("h1", [
            _vm._v(
              " " + _vm._s(_vm.$t("message.corporate.home.labels.title")) + " "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("message.corporate.home.labels.subtitle")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btn_video_wrapper" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-default btn-default-style",
                attrs: {
                  href: "https://oauth.incubie.com.br/#/credentials/register"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("message.corporate.home.buttom.registers")) +
                    " "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "countdown_time" }, [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("message.corporate.home.labels.getStarted")) +
                  " "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "clock-countdown" }, [
              _c("div", { staticClass: "site-config" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "coundown-timer d-flex justify-content-center" },
                [
                  _c(
                    "div",
                    { staticClass: "single-counter d-flex flex-column" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("span", { staticClass: "normal" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("message.corporate.home.fields.days")
                            ) +
                            " "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "single-counter d-flex flex-column" },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("span", { staticClass: "normal" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("message.corporate.home.fields.hours")
                            ) +
                            " "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "single-counter d-flex flex-column" },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("span", { staticClass: "normal" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("message.corporate.home.fields.minutes")
                            ) +
                            " "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "single-counter d-flex flex-column" },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("span", { staticClass: "normal" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("message.corporate.home.fields.seconds")
                            ) +
                            " "
                        )
                      ])
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "count-time_btn" }, [
              _c(
                "a",
                {
                  staticClass: "gradient-color",
                  attrs: {
                    href: "https://oauth.incubie.com.br/#/credentials/register",
                    title: ""
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("message.corporate.home.labels.launch"))
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(4)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("span", { staticClass: "days count-time gradient-color" }, [
        _vm._v("00")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("span", { staticClass: "hours count-time gradient-color" }, [
        _vm._v("00")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("span", { staticClass: "minutes count-time gradient-color" }, [
        _vm._v("00")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("span", { staticClass: "seconds count-time gradient-color" }, [
        _vm._v("00")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-sm-6 col-md-12 col-lg-6 img-wrapper" },
      [
        _c("div", { staticClass: "intro-img" }, [
          _c(
            "div",
            { staticClass: "bounce_wrapper", attrs: { "data-nosnippet": "" } },
            [
              _c("img", {
                attrs: { src: "assets/images/header-06.png", alt: "" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "shape-1" }, [
                _c("div", { staticClass: "box bounce-1" }, [
                  _c("img", {
                    attrs: {
                      src: "assets/images/bounce/header1_b.png",
                      alt: ""
                    }
                  })
                ])
              ])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0380da87", { render: render, staticRenderFns: staticRenderFns })
  }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer", attrs: { id: "footer-06" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row footer-btm-wrapper" }, [
        _c("div", { staticClass: "col-md-12 col-lg-12" }, [
          _c("div", { staticClass: "footer_items" }, [
            _c(
              "div",
              { staticClass: "footer-single-col footer_single_list_1" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("message.corporate.footer.labels.description")
                    )
                  }
                }),
                _vm._v(" "),
                _vm._m(1)
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-single-col footer_single_list_2" },
              [
                _c("h3", { staticClass: "subtitle_1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("message.corporate.footer.labels.company")
                      ) +
                      " "
                  )
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "list-unstyled" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/welcome" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "message.corporate.footer.labels.welcome"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/about" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("message.corporate.footer.labels.about")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/contact" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("message.corporate.footer.labels.work")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-single-col footer_single_list_3" },
              [
                _c("h3", { staticClass: "subtitle_1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("message.corporate.footer.labels.informations")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "list-unstyled" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/contact" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "message.corporate.footer.labels.contact"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/roadmap" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "message.corporate.footer.labels.roadmap"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/privacy" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "message.corporate.footer.labels.privacy"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/terms-of-use" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "message.corporate.footer.labels.termsConditions"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-single-col footer_single_list_4" },
              [
                _c("h3", { staticClass: "subtitle_1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("message.corporate.footer.labels.services")
                      ) +
                      " "
                  )
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "list-unstyled" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/features" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "message.corporate.footer.labels.services"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/price" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("message.corporate.footer.labels.price")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/common-questions" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.scrollTop($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("message.corporate.footer.labels.faq")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "copyright" }, [
      _c("p", [
        _vm._v(
          " " + _vm._s(_vm.$t("message.corporate.footer.labels.name")) + " "
        ),
        _c("br"),
        _vm._v(
          " " + _vm._s(_vm.$t("message.corporate.footer.labels.taxId")) + " "
        ),
        _c("br"),
        _vm._v(
          " " + _vm._s(_vm.$t("message.corporate.footer.labels.address")) + " "
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _vm._m(4)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "subtitle_1" }, [
      _vm._v("\n               \n              "),
      _c("img", { attrs: { src: "assets/images/gradient-logo.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "social-links list-unstyled ml-auto" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("i", { staticClass: "fab fa-facebook-f" })
        ])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("i", { staticClass: "fab fa-twitter" })
        ])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("i", { staticClass: "fab fa-linkedin-in" })
        ])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _c("a", { attrs: { href: "/" } }, [
          _c("i", { staticClass: "fab fa-instagram" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "shape1 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: {
            src: "assets/images/shape/home_6/footer_shape_1.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "shape2 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: {
            src: "assets/images/shape/home_6/footer_shape_2.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "shape3 header-shape", attrs: { "data-nosnippet": "" } },
      [
        _c("img", {
          attrs: {
            src: "assets/images/shape/home_6/footer_shape_3.png",
            alt: ""
          }
        })
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1260502b", { render: render, staticRenderFns: staticRenderFns })
  }
}
import { SET_LANGUAGE }       from './LanguageTypes'

import Languages              from '../../locales/translations';

const supportedLanguages = Object.getOwnPropertyNames(Languages);

const store = {
    namespaced: true,
    state: {
        
        language: localStorage.getItem('language'),
        languages: Languages

    },
    actions: {

        [SET_LANGUAGE]({ commit, dispatch }, languages) {

            let language = null

            if (typeof languages === 'string') 
                language = languages
            else 
                language = supportedLanguages.find(sl =>
                        languages.find( l => (l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null)) );
        }

    },
    getters: {

        language: state => {
            return state.language
        },
        current: state => country => {
            for(let language in state.languages) {
                if ( language.split('-')[1].toLowerCase() === country )
                    return language
            }
        }
        
    },
    mutations: {
        
        [SET_LANGUAGE](state, { language }) {
            localStorage.setItem('language', language);
            state.language = language;
        }

    }
}

export default {
    language: store
}